import Layout from "../components/layout";
import Landing from "../components/landing/Landing";
import Wrapper from "../components/wrapper/Wrapper.js";
import Description from "../components/description/Description";
import Features from "../components/features/Features";
import Schedule from "../components/schedule/Schedule";
import Access from "../components/access/Access";
import Price from "../components/price/Price";
import ContactForm from "../components/contactForm/ContactForm";
import SEO from "../components/seo";
import React, { useEffect } from "react";
import { graphql } from "gatsby";

import "@fontsource/noto-sans-jp/400.css";
import { schedule } from "../data/data";

const fadeAnimate = () => {
  const fadeBreakPoint = window.screen.height / 1.5;
  const elements = document.querySelectorAll(".section-wrapper");

  elements.forEach((element) => {
    if (Math.floor(element.getBoundingClientRect().top) <= fadeBreakPoint) {
      element.classList.add("active");
    }
  });
};

const mapGSheetsToDataStructure = (data) => {
  const total = data.reduce((total, current) => {
    const { dateOrder, beginTime, endTime } = current;
    const targetDatePair = [`${beginTime} - ${endTime}`];
    if (total[dateOrder]) {
      total[dateOrder].available.push(targetDatePair);
    } else {
      total[dateOrder] = {};
      total[dateOrder].available = [targetDatePair];
    }
    return total;
  }, {});
  const mapped = Object.keys(total).map((key) => ({
    date: Number(key),
    available: total[key].available,
  }));
  return mapped;
};

const Index = ({ data }) => {
  useEffect(() => {
    window.addEventListener("scroll", fadeAnimate);
    //cleanup
    return () => window.removeEventListener("scroll", fadeAnimate);
  }, []);

  // const mappedScheduledDates = data.allGoogleSheet1Sheet.edges
  //   .map((edge) => edge.node)
  //   .sort((a, b) => a.dateOrder > b.dateOrder)
  //   .filter((dateItem) => dateItem.beginTime);

  return (
    <Layout landing="landing">
      <SEO />
      <Landing />
      <Wrapper>
        <Description />
        <Features />
        <div className="access-schedule-wrapper">
          <Access />
          <Schedule scheduledData={schedule} />
        </div>
        <Price />
        <ContactForm />
      </Wrapper>
    </Layout>
  );
};

// export const query = graphql`
//   query MyQuery {
//     allGoogleSheet1Sheet {
//       edges {
//         node {
//           beginTime
//           date
//           endTime
//           id
//           dateOrder
//         }
//       }
//     }
//   }
// `;

export default Index;
