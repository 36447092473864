import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";

const LandingMessage = () => {
  return (
    <h1>
      {`
      ヨガは音楽に似ている。

      身体のリズム、

      心のメロディー、

      魂のハーモニーが

      人生という交響曲を奏でる。

      `}
      <span
        style={{
          color: "var(--orange)",
          fontStyle: "italic",
          fontSize: "1.5rem",
        }}
      >
        {" "}
        -B.K.S. アイアンガー
      </span>
    </h1>
  );
};

const LandingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  @media screen and (min-width: 768px) {
    width: 95%;
  }

  @media screen and (min-width: 960px) {
    width: 80%;
    margin-bottom: 48px;
    max-width: 900px;
    padding: 40px;
  }
`;
const LandingInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  h1 {
    font-size: 2rem;
    white-space: pre-line;
    line-height: 1.5;
    font-weight: initial;
  }

  a {
    align-self: center;
  }

  @media screen and (min-width: 768px) {
    margin: 0 16px;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
  }
`;

const LandingBtn = styled.div`
  padding: 24px;
  align-self: center;
  border: 2px solid var(--accent);
  margin-top: 48px;
  border-radius: 24px;
  font-size: 1.5rem;
  a {
    color: var(--accent);
    padding: 8px;
  }
  &:hover {
    background: var(--accent);
    transition: all 0.5s ease;
    a {
      color: var(--white);
    }
  }
  @media screen and (min-width: 768px) {
    align-self: auto;
  }
`;

const Landing = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "main.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage Tag="section" fluid={data.desktop.childImageSharp.fluid}>
      <LandingWrapper>
        <LandingInner>
          <LandingMessage />
          <LandingBtn>
            <Link to="/#contact" className="">
              お問い合わせ
            </Link>
          </LandingBtn>
        </LandingInner>
      </LandingWrapper>
    </BackgroundImage>
  );
};

export default Landing;
