import React from "react";
import { navigate } from "gatsby";
import Section from "../section/Section";
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactForm = () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        navigate(form.getAttribute("action"));
      })
      .catch((error) => alert(error));
  };

  return (
    <Section header="お問い合わせ" id="contact">
      <a href="tel:070-1273-7212">
        <span role="img" aria-label="電話">
          📞 　　
        </span>{" "}
        070-1273-7212
      </a>
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="inner-wrapper"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <br />
        <input
          className="form-input"
          type="text"
          name="name"
          placeholder="お名前"
          onChange={handleChange}
          required
        />
        <br />
        <input
          className="form-input"
          type="email"
          name="email"
          placeholder="メールアドレス"
          onChange={handleChange}
          required
        />
        <br />
        <textarea
          className="form-input"
          name="message"
          placeholder="お問い合わせ内容"
          onChange={handleChange}
          required
        />
        <button type="submit" className="btn btn-send">
          送信
        </button>
      </form>
    </Section>
  );
};

export default ContactForm;
