import React from "react";

const Day = ({ item, JapaneseDate }) => {
  return (
    <div key={item.date} className="schedule-day-container">
      <p style={{ textAlign: "start" }}>{JapaneseDate[item.date]}</p>
      <p>{item.memo}</p>
      <div className="schedule-day-time-container">
        {item.available.map((time, index) => {
          const [timeBegining, timeEnd] = time[0].split(" - ");
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: 0,
              }}
            >
              <p key={index}>
                <time>{timeBegining}</time>
                {` - `}
                <time>{timeEnd}</time>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Day;
