import React from "react";
import { JapaneseDate } from "../../data/data";
import Day from "./Day";
import Section from "../section/Section";
const Schedule = ({ scheduledData }) => {
  return (
    <Section header="スケジュール">
      <div className="inner-wrapper schedule-container">
        <div>
          {scheduledData.map((item) => (
            <Day key={item.date} item={item} JapaneseDate={JapaneseDate} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Schedule;
