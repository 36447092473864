import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Access = () => {
  return (
    <section className="section-wrapper map-section-wrapper">
      <h2>アクセス</h2>
      <a href="https://www.google.com/maps/place/恵那市笠置町毛呂窪630/">
        <figure className="figure-wrapper">
          <StaticImage
            src="../../images/google-map-1000.png"
            alt="スタジオの地図"
            layout="fullWidth"
            className="map"
          />
          <p>恵那駅より車でおよそ１０分で着きます。</p>
        </figure>
      </a>
    </section>
  );
};

export default Access;
