import React from "react";
import { features } from "../../data/data";
import Section from "../section/Section";
const Features = () => {
  return (
    <Section header="得られる効果">
      <div className="features-container">
        {features.map((item) => {
          return (
            <article key={item.id} className="features-box">
              <h3 style={{ color: `${item.color}` }}>{item.title}</h3>
              <p>{item.description}</p>
            </article>
          );
        })}
      </div>
    </Section>
  );
};

export default Features;
