import React from "react";
import Section from "../section/Section";
import styled from "styled-components";

const normalPrice = [
  {
    title: "１回",
    price: 2000,
  },
  {
    title: "５回　　回数券",
    price: 9000,
  },
  {
    title: "１０回　回数券",
    price: 15000,
  },
];

const privatePrice = [
  {
    title: "１時間",
    price: 5000,
  },
  {
    title: "１時間半",
    price: 6000,
  },
];

const TableWrapper = styled.table`
  margin: 0 auto;
  width: 100%;
  td,
  th {
    padding: 8px 0;
  }

  td {
    font-size: 1.3rem;
  }
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
  margin-top: 16px;

  @media screen and (max-width: 960px) {
    display: block;
  }
`;
const Price = () => {
  return (
    <Section header="料金">
      <div className="inner-wrapper">
        {/* <div> */}
        <p style={{ fontStyle: "italic" }}>
          初回は無料で一般レッスンを体験することができます。お気軽にお問い合わせください。
        </p>
        <TableContainer>
          <TableWrapper>
            <tbody>
              <tr>
                <td
                  colSpan="2"
                  align="center"
                  style={{ textDecoration: "underline" }}
                >
                  一般レッスン（１時間半）
                </td>
              </tr>

              {normalPrice.map((line, index) => {
                return (
                  <tr key={index}>
                    <td align="left">{line.title}</td>
                    <td align="right">{line.price}円</td>
                  </tr>
                );
              })}
            </tbody>
          </TableWrapper>
          <TableWrapper>
            <tbody>
              <tr>
                <td
                  colSpan="2"
                  align="center"
                  style={{ textDecoration: "underline" }}
                >
                  プライベートレッスン
                </td>
              </tr>

              {privatePrice.map((line, index) => {
                return (
                  <tr key={index}>
                    <td align="left">{line.title}</td>
                    <td align="right">{line.price}円</td>
                  </tr>
                );
              })}
            </tbody>
          </TableWrapper>
          {/* </div> */}
        </TableContainer>
      </div>
    </Section>
  );
};

export default Price;
