import React from "react";
import { Link } from "gatsby";
import Section from "../section/Section";
import { StaticImage } from "gatsby-plugin-image";

const DescriptionText = () => {
  return (
    <p>
      {`ヨガは身体が動かなくなっても最後の一呼吸まで続けられるものです。

      ヨガがもたらす自分の身体、心、魂の変化に驚き、この心地よさを他の人にも共有したいと思い指導員を目指し、インド・プーネにある`}

      <a
        href="https://rimyi.org/"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        ラママニ・アイアンガー・メモリアル・ヨガ・インスティチュート(RIMYI)
      </a>
      {`の認定指導員になりました。

      岐阜県恵那市の笠置山の麓に古民家を改装し、ヨガスタジオを作りました。恵那山を見渡せる気持ちの良い空間です。アイアンガーヨガに必要な設備用具を揃えています。どこにもある用具（椅子・ベルト・毛布等）を使うことによって身体に無理なく練習することができます。

      身体の硬さや年齢に関係なく誰でも始められるヨガを多くの人に知ってもらいたいと考えています。

      `}
      {`
      講師については`}
      <Link to="/about" className="link link-orange">
        こちら
      </Link>
      {`
        アイアンガーヨガについては`}
      <Link to="/iyengar" className="link link-orange">
        こちら
      </Link>
      {`

      `}
    </p>
  );
};

const Description = () => {
  return (
    <>
      <Section header="ヨガスタジオ笠置" id="description" className="active">
        <article className="inner-wrapper description-wrapper">
          <DescriptionText />
        </article>
        <figure>
          <StaticImage
            src="../../images/studio.jpeg"
            alt="スタジオの写真"
            layout="fullWidth"
          />
        </figure>
      </Section>
    </>
  );
};
export default Description;
